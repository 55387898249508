<template>
  <div class="register left-float-top">
    <el-row :gutter="20" style="width: 100%">
      <el-col :span="5">
        <el-menu :default-active="activeIndex" @select="handleSelect"
                 ref="menu" class="el-menu-vertical-demo" style="padding: 10px 0;" background-color="#187bb2" text-color="#fff" active-text-color="#ffd04b" >
          <el-menu-item index="1">
            <div slot="title" @click="goOrgan">机构信息</div>
          </el-menu-item>
          <el-menu-item index="2">
            <div slot="title" @click="goStationmessage">站内信</div>
          </el-menu-item>
          <el-menu-item index="3">
            <div slot="title" @click="goDataPlatform">登录数据填报平台</div>
          </el-menu-item>

          <el-menu-item index="5">
            <div slot="title" @click="goMaterials">建设材料</div>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="19"> <div><router-view></router-view></div></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Index",
  data(){
    return{
      activeIndex:'',
    }
  },
  mounted() {
    this.activeIndex = localStorage.getItem('activeIndex') || '1';
  },
  methods:{
    handleSelect(key, keyPath) {
      console.log(key)
      this.activeIndex = key;
      localStorage.setItem('activeIndex', key);
    },
    goOrgan:function (){
      this.$router.push('/unit/organ')
    },
    goStationmessage:function (){
      this.$router.push('/unit/station/message')
    },

    goMaterials:function (){
      this.$router.push('/unit/materials')
    },
    goDataPlatform:function (){
      window.open('http://localhost:8082/#/',"_blank");
    },

  }
}
</script>

<style scoped>

</style>